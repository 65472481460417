@font-face {
  font-family: Scrubland;
  src: url("./fonts/scrubland/SCRUBLAND.ttf") format("ttf");
  src: url("./fonts/scrubland/SCRUBLAND.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url("./fonts/Montserrat/static/Montserrat-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url("./fonts/Montserrat/static/Montserrat-SemiBold.ttf");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: IndieFlower;
  src: url("./fonts/Indie_Flower/IndieFlower-Regular.ttf");
  font-display: swap;
}
